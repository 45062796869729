
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-types",
  components: {},
  setup() {
    const { t } = useI18n();

    const plans = [
      {
        default: true,
        custom: false,
        features: [
          {
            title: " AI Otel Asistanı'na erişilebilirlik",
            supported: true,
          },
          {
            title: " Anketör modülüne erişilebilirlik",
            supported: true,
          },
          {
            title: " Mobil ve web üzerinden kolay erişim",
            supported: true,
          },
          {
            title: "Yeni eklenen modülleri satın alma",
            supported: false,
          },
        ],
      },
    ];

    onMounted(() => {
      setCurrentPageTitle(t("accountTypes.title"));
    });

    return { plans };
  },
});
